import styled from "styled-components";
import { defaults, colors } from "theme";

// Must match .swiper-slide padding in style.css
const swiperSlidePadding = 5;

export const Wrapper = styled.div`
  padding: 0 15px;
`;
export const Container = styled.div`
  background-color: ${colors.field};
  margin: 0;
  padding: 15px;
  border-radius: ${defaults.borderRadius};
  border: 0.5px solid #3d404a;
`;

export const Heading = styled.h2`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin: 0;
  color: ${colors.text};
  margin: 0;
  padding: 0 ${swiperSlidePadding}px;
`;

export const Paragraph = styled.p`
  font-size: 14px;
  color: ${colors.text};
  margin: 0;
`;
export const ResultGroupTitle = styled.h2`
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #ffffff;
`;

export const ResultsFound = styled.div`
  padding: 10px 0;
  background: #3f5ddc;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  margin: 0 0 32px;
  span {
    font-weight: 600;
    color: #ffffff;
  }
`;

export const OptionHolder = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  background: #3d404a;
  border-radius: 50px;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 10px;
  color: #dedede;
`;

export const Result = styled.div`
  & {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid ${colors.border};
  }
`;

export const ResultGroupHeading = styled.h3`
  font-size: 14px;
  text-align: center;
  line-height: 1.2;
  font-weight: 600;
  text-transform: capitalize;
  color: #dedede;
  margin: 0 0 16px 0;
  span {
    color: #fff;
  }
`;

export const ResultHeading = styled.h3`
  display: -webkit-box;
  font-size: 14px;
  min-height: 35px;
  line-height: 1.2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  color: #fff;
  margin: 0 0 10px 0;
`;

export const ResultTag = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #dedede;
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
  span {
    color: #fff;
    font-weight: 500;
  }
`;

export const AffiliateTag = styled.div`
  background: rgba(63, 93, 220, 0.35);
  text-transform: capitalize;
  padding: 4px;
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  margin-right: 8px;
`;

export const ReviewContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  color: #dedede;
  margin-bottom: 10px;
`;

export const ResultText = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  margin: 0 0 15px 0;
  -webkit-line-clamp: 3;
  min-height: 60px;
`;

export const ResultImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${defaults.borderRadius};
  overflow: hidden;
  margin-bottom: 14px;
  // background: rgba(255, 255, 255, 0.1);
  background: #fff;
  padding: 6px;

  img {
    width: 100%;
    display: block;
    max-height: 475px;
    object-fit: contain;
    aspect-ratio: 1 / 1;
  }
`;

export const ResultCta = styled.div`
  margin-bottom: 12px;
`;

export const ResultCtaHeading = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin: 0;
  span {
    font-size: 13px;
    font-weight: 500;
    font-family: Inter;
    color: #dedede;
  }
`;

export const BackToTop = styled.a`
  font-weight: 600;
  font-size: 15px;
  color: #fff;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px 0;
  gap: 10px;
  &:before {
    display: block;
    width: 17px;
    height: 10px;
    content: "";
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGggZD0ibTE1LjE0MyA4LjA3MS03LjA3MS03LjA3TDEgOC4wNyIgc3Ryb2tlPSIjNDQ1OERDIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
  }
`;
