export const SvgPencil = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="15" fill="#3F5DDC" />
    <path
      d="M23.6814 10.4444L20.5775 7.31919C20.3724 7.11476 20.0948 7 19.8055 7C19.5162 7 19.2386 7.11476 19.0335 7.31919L8.0298 18.3262L7.02514 22.6696C6.99048 22.8284 6.99167 22.9929 7.02862 23.1512C7.06557 23.3095 7.13734 23.4574 7.23869 23.5844C7.34005 23.7113 7.46842 23.8139 7.61444 23.8848C7.76046 23.9556 7.92044 23.9929 8.08268 23.9939C8.15825 24.002 8.23448 24.002 8.31005 23.9939L12.6935 22.9874L23.6814 11.9911C23.8854 11.7856 24 11.5075 24 11.2177C24 10.9279 23.8854 10.6498 23.6814 10.4444ZM12.1648 22.034L8.05624 22.8974L8.99216 18.8612L17.2251 10.6456L20.3977 13.8238L12.1648 22.034ZM21.1063 13.0557L17.9336 9.87759L19.7738 8.04486L22.8935 11.223L21.1063 13.0557Z"
      fill="white"
    />
  </svg>
);
