import styled from "styled-components";
import { colors, defaults } from "theme";

export const Container = styled.div`
  // background-color: ${colors.field};
  margin: 0 0 ${defaults.margin} 0;
  padding: 15px;
  border-radius: ${defaults.borderRadius};
`;

export const GroupContainer = styled.div`
  padding: 15px;
  background-color: #161618;
  margin-bottom: 8px;
  border-radius: ${defaults.borderRadius};
`;

export const GroupTitle = styled.h3`
  text-align: center;
  color: #e9e9e9;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 15px 0;
  div {
    margin-top: 0;
    color: #dedede;
  }
`;

export const Title = styled.h2`
  text-align: center;
  margin: 0 0 20px 0;
  color: ${colors.title};
  font-size: ${defaults.headingSize};
  font-weight: ${defaults.headingFontWeight};
`;

export const GroupedTabs = styled.div`
  // border-radius: ${defaults.borderRadius};
  // border: 0.75px solid ${colors.border};
`;

export const Disclaimer = styled.div`
  margin-top: 12px;
  font-size: 10px;
  line-height: 1.2;
  color: #dedede;
  text-align: center;
  span {
    color: #fff;
  }
`;
