export const SvgPlus = () => (
  <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="15" fill="#3F5DDC" />
    <path
      d="M15 8.75v12.5M21.25 14.625H8.75"
      stroke="#fff"
      stroke-width="1.4"
      stroke-linecap="round"
    />
  </svg>
);
