import { useState, useRef, useEffect } from "react";
import { v4 } from "uuid";
import {
  ModalProps,
  ModalContentProps,
  LikesOrDislikesValuesRaw,
} from "./types";
import { LikesDislikesDataResponse } from "../../types";
import {
  Modal,
  ModalInner,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from "components/Modal";
import { ButtonGrid, ButtonGridItem } from "components/ButtonGrid";
import { Toggle } from "components/Toggle";
import { Loader } from "components/Loader";
// import { Textarea } from "components/Form";
// import { Field } from "formik";
import { SearchInput, InputWrapper, SearchIcon } from "./style";
import { SvgSearch } from "svg/SvgSearch";

const ModalContent = ({
  modalType,
  onToggleModal,
  fieldValue,
  fieldKey,
  setFieldValue,
  setLikesOrDislikesSelections,
  likesOrDislikesSelections,
  locationValue,
  genderValue,
  ageValue,
  likesDislikesData,
  getLikesDislikes,
}: ModalContentProps) => {
  const [fieldValues, setFieldValues] = useState<string[]>([]);
  const [fieldValuesRaw, setFieldValuesRaw] = useState<
    LikesOrDislikesValuesRaw[]
  >([]);
  const [listType, setListType] = useState("STANDARD");
  const [searchQuery, setSearchQuery] = useState("");

  const hasSetInitialFieldValues = useRef<boolean>(false);
  useEffect(() => {
    if (!hasSetInitialFieldValues.current) {
      hasSetInitialFieldValues.current = true;
      if (fieldValue && fieldValue !== "") {
        setFieldValues(fieldValue.split(", "));
      }
    }
  }, [fieldValue]);

  const hasSetInitialFieldRawValues = useRef<boolean>(false);
  useEffect(() => {
    if (!hasSetInitialFieldRawValues.current) {
      hasSetInitialFieldRawValues.current = true;
      if (likesOrDislikesSelections) {
        setFieldValuesRaw(likesOrDislikesSelections);
      }
    }
  }, [likesOrDislikesSelections]);

  const hasFetchedLikesDislikes = useRef<boolean>(false);
  useEffect(() => {
    if (!hasFetchedLikesDislikes.current) {
      if (locationValue) {
        hasFetchedLikesDislikes.current = true;
        getLikesDislikes({
          location: locationValue,
          gender: genderValue,
          age: ageValue,
        });
      }
    }
  }, [locationValue, genderValue, ageValue, getLikesDislikes]);

  function handleItemClick(object: LikesOrDislikesValuesRaw) {
    if (fieldValues.includes(object.value)) {
      setFieldValues(fieldValues.filter((value) => value !== object.value));
      setFieldValuesRaw(
        fieldValuesRaw.filter((value) => value.value !== object.value)
      );
    } else {
      setFieldValues([...fieldValues, object.value]);
      setFieldValuesRaw([...fieldValuesRaw, object]);
    }
  }

  function isItemActive(fieldValue: string) {
    return fieldValues.includes(fieldValue);
  }

  function handleSaveSelections() {
    setFieldValue(fieldKey, fieldValues.join(", "));
    setLikesOrDislikesSelections(fieldValuesRaw);
    onToggleModal();
  }

  function getPopularOptions(likesDislikesData: LikesDislikesDataResponse) {
    if (typeof likesDislikesData.likes === "object") {
      return Object.entries(likesDislikesData.likes)
        .map(([key, values]) =>
          values.map((value) => ({
            value,
            key: { key, value: values },
            id: v4(),
          }))
        )
        .flat();
    } else {
      return [];
    }
  }

  function getStandardOptions(likesDislikesData: LikesDislikesDataResponse) {
    if (typeof likesDislikesData.standard === "object") {
      return Object.entries(likesDislikesData.standard)
        .map(([key, values]) =>
          values.map(() => ({
            value: key,
            key: { key, value: values },
            id: v4(),
          }))
        )
        .flat();
    } else {
      return [];
    }
  }

  function renderOptions() {
    if (likesDislikesData) {
      const filterOptions = (options: any[]) =>
        options.filter((option) =>
          option.value.toLowerCase().includes(searchQuery.toLowerCase())
        );

      if (
        listType === "POPULAR" &&
        typeof likesDislikesData.likes === "object"
      ) {
        const popularOptions = filterOptions(
          getPopularOptions(likesDislikesData)
        );
        if (popularOptions.length) {
          return (
            <ButtonGrid>
              {popularOptions.map((option) => (
                <ButtonGridItem
                  $isActive={isItemActive(option.value)}
                  $hasLabel={true}
                  type="button"
                  onClick={() => handleItemClick(option)}
                  key={option.id}
                >
                  {/*<span>{option.key.key}</span>*/}
                  {option.value}
                </ButtonGridItem>
              ))}
            </ButtonGrid>
          );
        }
      } else if (
        listType === "STANDARD" &&
        typeof likesDislikesData.standard === "object"
      ) {
        const standardOptions = filterOptions(
          getStandardOptions(likesDislikesData)
        );
        if (standardOptions.length) {
          return (
            <ButtonGrid>
              {standardOptions.map((option) => (
                <ButtonGridItem
                  $isActive={isItemActive(option.value)}
                  $hasLabel={true}
                  type="button"
                  onClick={() => handleItemClick(option)}
                  key={option.id}
                >
                  {option.value}
                </ButtonGridItem>
              ))}
            </ButtonGrid>
          );
        } else {
          return (
            <small>Hit enter or return to add this to their profile.</small>
          );
        }
      }
    }
    return null;
  }

  // function removeLastSelection() {
  //   setFieldValues((prevFieldValues) => {
  //     if (prevFieldValues.length === 0) return prevFieldValues;
  //     const newFieldValues = [...prevFieldValues];
  //     newFieldValues.pop();
  //     return newFieldValues;
  //   });
  //   setFieldValuesRaw((prevFieldValuesRaw) => {
  //     if (prevFieldValuesRaw.length === 0) return prevFieldValuesRaw;
  //     const newFieldValuesRaw = [...prevFieldValuesRaw];
  //     newFieldValuesRaw.pop();
  //     return newFieldValuesRaw;
  //   });
  // }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter" && searchQuery.trim() !== "") {
      const newValue = searchQuery.trim();
      if (!fieldValues.includes(newValue)) {
        setFieldValues([...fieldValues, newValue]);

        setFieldValuesRaw([
          ...fieldValuesRaw,
          {
            value: newValue,
            key: { key: newValue, value: [newValue] },
            id: v4(),
          },
        ]);
      }
      setSearchQuery("");
    }
  }

  return (
    <ModalInner>
      {!likesDislikesData ? (
        <Loader variant="absolute" backdrop="transparent" />
      ) : null}
      <ModalHeader>
        <ModalTitle>
          {modalType === "DISLIKES" ? "Dislikes" : "Chose their Interests"}
        </ModalTitle>
        <Toggle
          style={{ marginTop: 30, marginBottom: 10 }}
          options={[
            {
              label: "Common Interests",
              isActive: listType === "STANDARD",
              onClick: () => setListType("STANDARD"),
              key: "STANDARD",
            },
            {
              label: "What's Popular",
              isActive: listType === "POPULAR",
              onClick: () => setListType("POPULAR"),
              key: "POPULAR",
            },
          ]}
        />
        <InputWrapper>
          <SearchIcon>
            <SvgSearch />
          </SearchIcon>
          <SearchInput
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </InputWrapper>
      </ModalHeader>
      <ModalBody>{renderOptions()}</ModalBody>
      <ModalFooter>
        {/* <Field
          id="likesDislikesInput"
          name="likesDislikesInput"
          component={Textarea}
          style={{}}
          readOnly
          value={fieldValues.join(", ")}
        /> */}
        <ButtonGrid>
          <ButtonGridItem
            $isActive={fieldValues.length > 0}
            $removeBorder={true}
            type="button"
            onClick={onToggleModal}
          >
            {` < Back`}
          </ButtonGridItem>
          <ButtonGridItem
            $isActive={fieldValues.length > 0}
            $hasBg={true}
            $removeBorder={true}
            type="submit"
            onClick={handleSaveSelections}
          >
            Save
          </ButtonGridItem>
        </ButtonGrid>
      </ModalFooter>
    </ModalInner>
  );
};

const LikesOrDislikesModal = ({
  modalType,
  isModalActive,
  setModalActive,
  setFieldValue,
  fieldValue,
  fieldKey,
  setLikesOrDislikesSelections,
  likesOrDislikesSelections,
  locationValue,
  genderValue,
  ageValue,
  likesDislikesData,
  getLikesDislikes,
}: ModalProps) => {
  const onToggleModal = () => {
    setModalActive(false);
  };

  return (
    <Modal isOpen={isModalActive} toggleModal={onToggleModal} slide>
      <ModalContent
        modalType={modalType}
        onToggleModal={onToggleModal}
        fieldKey={fieldKey}
        fieldValue={fieldValue}
        setFieldValue={setFieldValue}
        setLikesOrDislikesSelections={setLikesOrDislikesSelections}
        likesOrDislikesSelections={likesOrDislikesSelections}
        locationValue={locationValue}
        genderValue={genderValue}
        ageValue={ageValue}
        likesDislikesData={likesDislikesData}
        getLikesDislikes={getLikesDislikes}
      />
    </Modal>
  );
};

export default LikesOrDislikesModal;
