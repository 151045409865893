import styled from "styled-components";
import { defaults } from "../../theme";

export const StyledToggle = styled.div`
  border-radius: 6px;
  border-width: 0.75px;
  border-style: solid;
  background-color: #3d404a;
  border-color: #3d404a;
  padding: 1px;
  height: 42px;
  width: 100%;
  display: flex;
`;

export const ToggleOption = styled.button<{ $isActive: boolean }>`
  font-family: ${defaults.fontFamily};
  font-size: 13px;
  font-weight: ${(props) => (props.$isActive ? "500" : "400")};
  line-height: 1.5;
  color: #b1b1b1;
  text-decoration: none;
  text-align: center;
  background-color: ${(props) => (props.$isActive ? "#111111" : "transparent")};
  border: ${(props) => (props.$isActive ? "1px solid #111111" : "none")};
  border-radius: 6px;
  width: 50%;
  position: relative;
  padding: 0 12px;
  outline: 0;
  cursor: ${(props) => (props.$isActive ? "default" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  transition: ${defaults.transitionFull};
  span {
    font-weight: 500;
    color: #fff;
  }

  &:hover {
    color: ${(props) =>
      props.$isActive
        ? "rgba(255, 255, 255, 0.5)"
        : "rgba(255, 255, 255, 0.5)"};
  }
`;
