import { useState } from "react";
import { ButtonLink } from "components/ButtonLink";
import { SvgLogo } from "svg/SvgLogo";
import { Container, LogoContainer } from "./style";

import { Widget } from "@typeform/embed-react";

import { Modal, ModalInner } from "components/Modal";

export function Feedback() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Container>
      <LogoContainer>
        <SvgLogo />
      </LogoContainer>
      <p>
        We care about your feedback. Please help us make Givving better by
        telling us about your experience today – it takes just 30-seconds.
      </p>
      <ButtonLink
        style={{ maxWidth: "200px" }}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Give feedback
      </ButtonLink>
      <Modal
        isOpen={isOpen}
        toggleModal={() => {
          setIsOpen(!isOpen);
        }}
        slide
      >
        <ModalInner>
          <Widget id="vTSnpx2x" style={{ height: "100%" }} />
        </ModalInner>
      </Modal>
    </Container>
  );
}
