import { useState } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import {
  Container,
  Disclaimer,
  GroupContainer,
  GroupedTabs,
  GroupTitle,
  Title,
} from "./style";
import { Notification } from "components/Notification";
import { Button, ButtonContainer } from "components/Button";
import { Select, Textarea, InputNumber } from "components/Form";
import { FormFields } from "../types";
import { LikesOrDislikesValuesRaw } from "./LikesOrDislikesModal/types";
import LikesOrDislikesModal from "./LikesOrDislikesModal";
import { Props } from "./types";
import { Toggle } from "components/Toggle";
import { SvgPlus } from "svg/SvgPlus";
import { SvgPencil } from "svg/SvgPencil";

export const validationSchema = Yup.object().shape({
  budget: Yup.string().required("Budget is required"),
});

export function Search({
  getGiftSuggestions,
  isLoadingSuggestions,
  isWebsocketReady,
  configMetaData,
  getLikesDislikes,
  likesDislikesData,
  errorData,
  loadingData,
}: Props) {
  // Store likes & dislikes selections as an object to make it easier to submit it to the API later
  const [likesSelections, setLikesSelections] = useState<
    LikesOrDislikesValuesRaw[]
  >([]);
  const [dislikesSelections, setDislikesSelections] = useState<
    LikesOrDislikesValuesRaw[]
  >([]);
  const [likesModalActive, setLikesModalActive] = useState(false);
  const [dislikesModalActive, setDislikesModalActive] = useState(false);
  const [writeItActive, setWriteItActive] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const genderOptions = configMetaData?.gender ?? [];
  const locationOptions = configMetaData?.location ?? [];
  const ageOptions = configMetaData?.age ?? [];

  const onSubmit = async (formValues: FormFields) => {
    setHasSubmitted(true);
    const uniqueLikesArray: string[] = [];
    likesSelections.forEach((item) => {
      item.key.value.forEach((value) => {
        if (!uniqueLikesArray.includes(value)) {
          uniqueLikesArray.push(value);
        }
      });
    });

    const uniqueDislikesArray: string[] = [];
    dislikesSelections.forEach((item) => {
      item.key.value.forEach((value) => {
        if (!uniqueDislikesArray.includes(value)) {
          uniqueDislikesArray.push(value);
        }
      });
    });

    // Remove the about field if the build it toggle is active
    if (!writeItActive) {
      formValues.about = "";
    }

    getGiftSuggestions({
      queryFields: {
        ...formValues,
        likes: uniqueLikesArray,
        dislikes: uniqueDislikesArray,
      },
    });
  };

  const fieldIdPrefix = "search";
  return (
    <Container>
      <Title>Find a gift for anyone</Title>
      <Formik
        enableReinitialize
        initialValues={{
          budget: "",
          who: "",
          about: "",
          location: "",
          age: "",
          likes: "",
          dislikes: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, values, setFieldValue, isSubmitting }) => {
          // const isLikesDislikesDisabled = !values.location.length;
          return (
            <form onSubmit={handleSubmit}>
              <GroupContainer>
                <GroupTitle>Enter the gift basics</GroupTitle>

                <Field
                  id={`${fieldIdPrefix}-budget`}
                  name="budget"
                  label={["Your ", <span>Budget</span>]}
                  // min={"0"}
                  component={InputNumber}
                  // type="number"
                  isRequired
                  isClearable
                />
                <Field
                  id={`${fieldIdPrefix}-age`}
                  name="age"
                  label={["Their ", <span>Age</span>]}
                  component={Select}
                  options={ageOptions.map((option) => ({
                    label: option,
                    value: option,
                  }))}
                  isClearable
                  onChange={(
                    option: {
                      value: string;
                    } | null,
                    field: { name: string }
                  ) => {
                    setFieldValue(field.name, option ? option.value : "");

                    // Unset previous likes & dislikes values if set
                    if (values.likes) {
                      setFieldValue("likes", "");
                    }
                    if (values.dislikes) {
                      setFieldValue("dislikes", "");
                    }
                  }}
                />
                <Field
                  id={`${fieldIdPrefix}-who`}
                  name="who"
                  label={["Their ", <span>Gender</span>]}
                  component={Select}
                  options={genderOptions.map((option) => ({
                    label: option,
                    value: option,
                  }))}
                  isClearable
                  onChange={(
                    option: {
                      value: string;
                    } | null,
                    field: { name: string }
                  ) => {
                    setFieldValue(field.name, option ? option.value : "");

                    // Unset previous likes & dislikes values if set
                    if (values.likes) {
                      setFieldValue("likes", "");
                    }
                    if (values.dislikes) {
                      setFieldValue("dislikes", "");
                    }
                  }}
                />
                <Field
                  id={`${fieldIdPrefix}-location`}
                  name="location"
                  label={["Their ", <span>Country</span>]}
                  component={Select}
                  options={locationOptions.map((option) => ({
                    label: option,
                    value: option,
                  }))}
                  isClearable
                  isRequired
                  onChange={(
                    option: {
                      value: string;
                    } | null,
                    field: { name: string }
                  ) => {
                    setFieldValue(field.name, option ? option.value : "");

                    // Unset previous likes & dislikes values if set
                    if (values.likes) {
                      setFieldValue("likes", "");
                    }
                    if (values.dislikes) {
                      setFieldValue("dislikes", "");
                    }
                  }}
                />
              </GroupContainer>

              {/* Toggle */}
              <GroupContainer>
                <GroupTitle>
                  Enhance the gift ideas
                  <Disclaimer>
                    Use one of these options to get better results
                  </Disclaimer>
                </GroupTitle>
                <GroupedTabs>
                  <Toggle
                    options={[
                      {
                        label: (
                          <>
                            Select Their <span>Interests</span>
                          </>
                        ),
                        key: "about",
                        onClick: () => {
                          setWriteItActive(false);
                        },
                        isActive: writeItActive === false,
                      },
                      {
                        label: (
                          <>
                            Write <span>About Them</span>
                          </>
                        ),
                        key: "likes",
                        onClick: () => {
                          setWriteItActive(true);
                        },
                        isActive: writeItActive === true,
                      },
                    ]}
                  />
                  {writeItActive ? (
                    <Field
                      id={`${fieldIdPrefix}-about`}
                      name="about"
                      // label="About Them (Recommended)"
                      tooltip={`Tell us a little about their personality, values or anything relevant for personalised gift ideas. Example: "They work in marketing. They're interested in fashion, travel and fitness"`}
                      customLabel={
                        <>
                          <SvgPencil />
                          <div>
                            <p>
                              Tap here to write <span>About Them</span>
                              <br />
                              You could include their unique interests, hobbies,
                              work, favourite brands or goals.
                            </p>
                            {/* You could include their interests, hobbies, what they
                          do for work, favourite brands, their goals, or things
                          they love. */}
                          </div>
                        </>
                      }
                      component={Textarea}
                      containerStyle={{ marginTop: "4px" }}
                    />
                  ) : (
                    <Field
                      id={`${fieldIdPrefix}-likes`}
                      name="likes"
                      // label="Interests (Optional)"
                      tooltip={`Select what they’re interested in from our lists, or type their interests into the About Them field. Less is usually best. The lists will load once you set a gender and age.`}
                      // placeholder={`Select 1-5 options from our Common Interests or What’s Popular lists. What’s Popular requires an age and gender.`}
                      component={Textarea}
                      onClick={() => {
                        setLikesModalActive(true);
                      }}
                      // disabled={isLikesDislikesDisabled}
                      readOnly
                      containerStyle={{ marginTop: "4px" }}
                      customLabel={
                        <>
                          <SvgPlus />
                          <div>
                            <p>
                              Tap here to choose their <span>Interests</span>
                            </p>
                            {/* Select their interests from our Common and What’s
                          Popular lists (What’s Popular requires their Age and
                          Gender ID). */}
                          </div>
                        </>
                      }
                    />
                  )}
                </GroupedTabs>
              </GroupContainer>
              {/*<Field
                id={`${fieldIdPrefix}-dislikes`}
                name="dislikes"
                label="Dislikes (Optional)"
                tooltip={`Select things that they don't like or may
not like from our list. This helps us 
recommend gifts.`}
                component={Textarea}
                onClick={() => {
                  setDislikesModalActive(true);
                }}
                disabled={isLikesDislikesDisabled}
                readOnly
              />*/}

              {/* Popups */}
              <LikesOrDislikesModal
                modalType="LIKES"
                isModalActive={likesModalActive}
                setModalActive={setLikesModalActive}
                setFieldValue={setFieldValue}
                fieldValue={values.likes}
                fieldKey="likes"
                setLikesOrDislikesSelections={setLikesSelections}
                likesOrDislikesSelections={likesSelections}
                locationValue={values.location || "Australia"}
                genderValue={values.who}
                ageValue={values.age}
                likesDislikesData={likesDislikesData}
                getLikesDislikes={getLikesDislikes}
              />
              <LikesOrDislikesModal
                modalType="DISLIKES"
                isModalActive={dislikesModalActive}
                setModalActive={setDislikesModalActive}
                setFieldValue={setFieldValue}
                fieldValue={values.dislikes}
                fieldKey="dislikes"
                setLikesOrDislikesSelections={setDislikesSelections}
                likesOrDislikesSelections={dislikesSelections}
                locationValue={values.location}
                genderValue={values.who}
                ageValue={values.age}
                likesDislikesData={likesDislikesData}
                getLikesDislikes={getLikesDislikes}
              />

              {errorData?.result ? (
                <Notification
                  title="Error"
                  message={errorData.result.error}
                  color="red"
                  style={{ margin: "30px 0" }}
                />
              ) : null}
              <ButtonContainer>
                <Button
                  icon="search"
                  type="submit"
                  isLoading={isLoadingSuggestions}
                  loadingData={loadingData}
                  disabled={!isWebsocketReady || isSubmitting}
                >
                  {hasSubmitted ? "Find more gifts" : "Find gifts"}
                </Button>
              </ButtonContainer>
              <Disclaimer>&nbsp;</Disclaimer>
              <Disclaimer>
                <span>DISCLAIMER:</span> Givving is in its early stages of
                development. It can and will make mistakes. Contact us if you
                have any questions or concerns.
              </Disclaimer>
            </form>
          );
        }}
      </Formik>
    </Container>
  );
}
