import styled from "styled-components";
import { defaults } from "theme";

export const Container = styled.div`
  background-color: #1d1d1f;
  margin: ${defaults.margin} 0;
  padding: 24px 30px;
  border-radius: ${defaults.borderRadius};
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  p {
    font-size: 13px;
    line-height: 1.1;
    margin: 20px 10px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Aligns the logo to the left */
  max-width: 80px;
`;
