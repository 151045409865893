import React from "react";
import { FormProps } from "../types";
import { getFieldError } from "utils/form";
import { FormGroup } from "../FormGroup";
import { FormLabel } from "../FormLabel";
import { FormError } from "../FormError";
import { CustomLabel, StyledTextarea } from "./style";

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> &
  FormProps & {
    customLabel?: React.ReactNode;
  };

export const Textarea = ({
  id,
  onChange,
  label,
  isRequired,
  isLoading,
  tooltip,
  theme,
  validationTheme,
  containerStyle,
  form,
  field,
  customLabel,
  ...rest
}: TextareaProps) => {
  const fieldError = getFieldError({
    form,
    field,
  });

  const [isFocused, setIsFocused] = React.useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    setIsFocused(false);
    if (onChange) {
      onChange(event);
    }
  };

  const shouldShowCustomLabel = !isFocused && !field.value;

  return (
    <FormGroup isLoading={isLoading} style={containerStyle}>
      {label && (
        <FormLabel htmlFor={id} isRequired={isRequired} tooltip={tooltip}>
          {label}
        </FormLabel>
      )}
      <StyledTextarea
        {...field}
        id={id}
        onChange={onChange || field.onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        $hasError={fieldError ? true : false}
        $theme={theme}
        {...rest}
      />
      {customLabel && shouldShowCustomLabel && (
        <CustomLabel>{customLabel}</CustomLabel>
      )}
      {fieldError && (
        <FormError validationTheme={validationTheme}>{fieldError}</FormError>
      )}
    </FormGroup>
  );
};
