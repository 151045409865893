import styled from "styled-components";
import { FormStyleProps } from "../types";
import { formElementBaseStyle, formElementStyle } from "../style";

export const StyledTextarea = styled.textarea<FormStyleProps>`
  ${formElementBaseStyle};
  font-size: ${formElementStyle.textareaFontSize};
  line-height: ${formElementStyle.textareaLineHeight};
  padding-top: 10px;
  padding-bottom: 10px;
  height: ${formElementStyle.textareaHeight};
  resize: none;
  min-height: ${formElementStyle.textareaHeight};
  max-height: ${formElementStyle.textareaMaxHeight};
`;

export const CustomLabel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  height: 100%;
  align-items: center;
  gap: 8px;
  padding: 12px;
  user-select: none;
  pointer-events: none;
  font-size: 12px;
  line-height: 1.4;
  color: #dedede;
  svg {
    flex-shrink: 0;
    width: 30px;
  }
  p {
    color: #b1b1b1;
    font-size: 13px;
    margin: 0 0 6px 0;
    span {
      color: #fff;
      font-weight: 500;
    }
  }
`;
